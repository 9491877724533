import { Grid as MUIGrid } from '@mui/material';
import type { GridProps as MUIGridProps } from '@mui/material';

export const Grid = (props: MUIGridProps) => {
  if (props.container) {
    return <MUIGrid columns={4} columnSpacing={2} {...props} />;
  }
  if (props.item) {
    return <MUIGrid flexGrow={1} {...props} />;
  }
  return <MUIGrid {...props} />;
};
