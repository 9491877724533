import { MUT_CONFIG_KEY_LOCALSTORAGE } from './../constants';
import { ContentModuleFieldTypes } from '../types';
import { LOCALE_CODE, IEntry } from './../contentfulTypes';
/**
 * Remove hash value from URL
 * @returns  {void}
 */
export const removeHash = (): void =>
  history.replaceState('', document.title, location.pathname + location.search);

/**
 * Determine the mobile operating system.
 * Source: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
 * @returns {string}
 */
export const getMobileOperatingSystem = (): 'Windows Phone' | 'Android' | 'iOS' | 'unknown' => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

//get locale from localStorage
export const getLocaleFromLocalStorage = (): LOCALE_CODE | null => {
  if (localStorage) {
    const config = localStorage.getItem(MUT_CONFIG_KEY_LOCALSTORAGE);

    if (typeof config === 'string' && JSON.parse(config).locale) {
      return JSON.parse(config).locale;
    }
  }
  return null;
};

//convert locale to available locales (fallback is 'de-DE')
export const mapBrowserLocale = (): LOCALE_CODE => {
  let locale: LOCALE_CODE = 'de-DE';
  if (typeof navigator.language === 'string') {
    if (navigator.language.startsWith('en')) locale = 'en';
    if (navigator.language.startsWith('de-AT')) locale = 'de-AT';
  }
  return locale;
};

//used in pages/components for e.g. language changes
export const getLocale = (): LOCALE_CODE => {
  return getLocaleFromLocalStorage() ?? mapBrowserLocale();
};

export const extractItemFromContentModules = <ContentType extends IEntry>(
  content?: ContentModuleFieldTypes<unknown>[],
  type?: string,
  id?: string,
): ContentType | undefined => {
  if (type) {
    return content?.find((item): item is ContentType => item.sys.contentType?.sys.id === type);
  }
  if (id) {
    return content?.find((item): item is ContentType => item.sys.id === id);
  }
  return undefined;
};

export const extractItemsFromContentModules = <ContentType extends IEntry>(
  content: ContentModuleFieldTypes<unknown>[] | undefined,
  type: string,
): ContentType[] | undefined => {
  return content?.filter((item): item is ContentType => item.sys.contentType?.sys.id === type);
};

//see https://www.geodatasource.com/developers/javascript
export const computeDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string,
): number => {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};
