import { Button as MUIButton, styled } from '@mui/material';
import type { ButtonProps as MUIButtonProps } from '@mui/material';

export type MapButtonProps = MUIButtonProps & {
  /**
   * Custom props
   */
};

const _Button = (props: MapButtonProps) => {
  return <MUIButton {...props} variant="contained" />;
};

export const MapButton = styled(_Button)<MapButtonProps>(({ theme: { palette } }) => ({
  backgroundColor: palette.primary.main,
  borderRadius: '21px',
  minWidth: '110px',
  position: 'absolute',
}));
