import { Button, Icon } from 'ui';
import type { ButtonProps } from 'ui';
import { tracking } from '../../lib/trackings';
import { useStaticTexts } from '../../stores/useStaticTexts';

export function EmergencyContactButton({ ...props }: ButtonProps): JSX.Element {
  const { StaticTexts } = useStaticTexts();
  return (
    <Button
      {...props}
      color="tertiary"
      variant="contained"
      startIcon={<Icon.PhoneIphone />}
      href={`tel:${StaticTexts.emergencyNumber}`}
      onClick={() => tracking.track('emergency_call')}
    />
  );
}
