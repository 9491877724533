import create from 'zustand';

interface StaticTextsStoreProps {
  StaticTexts: Record<string, string>;
  setStaticTexts: (StaticTexts: Record<string, string>) => void;
}

export const useStaticTexts = create<StaticTextsStoreProps>((set) => {
  return {
    StaticTexts: {},
    setStaticTexts: (StaticTexts) => set({ StaticTexts }),
  };
});
