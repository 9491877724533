import { ReactNode } from 'react';
import { MUI } from 'ui';

type HeaderWrapperProps = {
  backgroundImage?: string;
  children: ReactNode;
};

export function HeaderWrapper({ backgroundImage, children }: HeaderWrapperProps): JSX.Element {
  return (
    <MUI.Box
      id="header-wrapper"
      component="header"
      position="relative"
      paddingBottom={2}
      paddingTop={6}
      paddingX={3}
      sx={{
        background: (theme) => theme.palette.background.headerGradient,
        backgroundImage: backgroundImage,
        backgroundSize: 'cover',
      }}
    >
      {children}
    </MUI.Box>
  );
}
