import { BottomNavigation as MUIBottomNavigation, styled } from '@mui/material';
import type { BottomNavigationProps as MUIBottomNavigationProps } from '@mui/material';

type BottomNavigationProps = MUIBottomNavigationProps & {
  /**
   * Custom props
   */
  height: string;
};

export const _BottomNavigation = ({ height, ...props }: BottomNavigationProps) => {
  return <MUIBottomNavigation {...props} showLabels sx={{ height }} />;
};

export const BottomNavigation = styled(_BottomNavigation)<BottomNavigationProps>(
  ({ theme: { spacing } }) => ({
    // TODO: eventually add color to palette
    backgroundColor: '#3C3C3B',
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  }),
);
