import { Paper as MUIPaper, styled } from '@mui/material';
import type { PaperProps as MUIPaperProps } from '@mui/material';

export type PaperProps = MUIPaperProps & {
  /**
   * Custom props
   */
};

const _Paper = (props: PaperProps) => {
  return <MUIPaper {...props}></MUIPaper>;
};

export const Paper = styled(_Paper)<PaperProps>(({ theme: { spacing } }) => ({
  padding: `${spacing(1)} ${spacing(2.75)}`,
  border: 'none',
  borderRadius: '21px',
  backgroundColor: 'inherit',
}));

export const ChatPaper = styled(_Paper)<PaperProps>(({ theme: { spacing, palette } }) => ({
  padding: `${spacing(1)} ${spacing(2)}`,
  border: 'none',
  width: '70%',
  borderRadius: '16px',
  borderTopLeftRadius: '0px',
  backgroundColor: palette.secondary.light,
  margin: `${spacing(1)} auto 0`,
}));
