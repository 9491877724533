import { ReactNode } from 'react';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypeText {
    darkPrimary?: string;
  }

  interface TypeBackground {
    transparent?: string;
    boxShadow?: string;
    boxShadowKidsButtons?: string;
    textShadow?: string;
    imageOverlay?: string;
    headerGradient?: string;
    white?: string;
    success?: string;
    successBackground?: string;
    kidsSliderBackground?: string;
    kidsBackground: string;
    kidsMessageButtonBackground: string;
    kidsPhoneButtonBackground: string;
  }

  interface PaletteColorOptions {
    light?: string;
    main?: string;
    dark?: string;
    contrastText?: string;
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    title: TypographyStyle;
    subtitle: TypographyStyle;
    paragraph: TypographyStyle;
    helperText: TypographyStyle;
    alertTitle: TypographyStyle;
  }

  interface TypographyOptions {
    title?: TypographyStyleOptions;
    subtitle?: TypographyStyleOptions;
    paragraph?: TypographyStyleOptions;
    helperText?: TypographyStyleOptions;
    alertTitle?: TypographyStyleOptions;
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    subtitle: true;
    paragraph: true;
    helperText: true;
    alertTitle: true;
  }
}

const HeadlineFont = 'Blinker, sans-serif';
const SecondFont = 'Roboto, sans-serif';

export const padding = {
  genericListItem: '10px 35px 10px 24px',
  NavigationBarApp: '75px',
  NavigationBarWeb: '40px',
};

const theme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      light: '#ACACFF',
      main: '#1B1B70;',
      dark: '#0C0C50',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#F1EDF7',
      main: '#C9BBE1',
      dark: '#B19CD7',
      contrastText: '#3A2444',
    },
    tertiary: {
      light: '#FFE7BC',
      main: '#F5B747',
      dark: '#DC924D',
      contrastText: '#2C1805',
    },
    background: {
      //standard background
      default: '#FAFAFA',
      //all Boxes/Accordions (which are clickable)
      paper: '#FFFFFF',
      transparent: 'transparent',
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)',
      boxShadowKidsButtons: '0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      textShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
      imageOverlay: 'rgba(48, 48, 48, 0.23)',
      headerGradient: 'linear-gradient(122.94deg, #8160BB 1.94%, #5B3B92 100%)',
      success: '#2E7D32',
      successBackground: '#ebf5eb',
      kidsSliderBackground: '#3F3F3F',
      kidsBackground: '#303030',
      kidsMessageButtonBackground: '#795BE1',
      kidsPhoneButtonBackground: '#2B69E7',
    },
    text: {
      primary: '#322734',
      secondary: 'rgba(60, 60, 59, 0.6)',
      disabled: 'rgba(60, 60, 59, 0.38)',
      //Dark.Text.Primary
      darkPrimary: '#FFFFFF',
    },
  },
  typography: () => {
    return {
      h1: {
        fontFamily: HeadlineFont,
        fontWeight: 600,
      },
      h2: {
        fontFamily: HeadlineFont,
        fontWeight: 600,
      },
      h3: {
        fontFamily: HeadlineFont,
        fontWeight: 600,
      },
      h4: {
        fontFamily: HeadlineFont,
        fontWeight: 600,
      },
      h5: {
        fontFamily: HeadlineFont,
        fontWeight: 600,
      },
      h6: {
        fontFamily: HeadlineFont,
        fontWeight: 600,
        lineHeight: '24px',
      },
      subtitle1: {
        fontFamily: SecondFont,
        fontWeight: 700,
        lineHeight: '20px',
      },
      subtitle2: {
        fontFamily: SecondFont,
        fontWeight: 700,
        lineHeight: '18px',
      },
      button: {
        fontFamily: SecondFont,
        fontWeight: 600,
      },
      caption: {
        fontFamily: SecondFont,
        fontWeight: 600,
      },
      overline: {
        fontFamily: SecondFont,
        fontWeight: 600,
      },
      body1: {
        fontFamily: SecondFont,
        fontWeight: 400,
      },
      body2: {
        fontFamily: SecondFont,
        fontWeight: 400,
      },
      helperText: {
        fontFamily: SecondFont,
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.4px',
      },
      avatarLetter: {
        fontFamily: SecondFont,
        fontWeight: 400,
        fontSize: '20px',
        letterSpacing: '0.14px',
      },
      inputLabel: {
        fontFamily: SecondFont,
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: '0.15px',
      },
      inputText: {
        fontFamily: SecondFont,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
      tooltip: {
        fontFamily: SecondFont,
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '143%',
      },
      alertTitle: {
        fontFamily: SecondFont,
        fontWeight: 600,
        fontSize: '16px',
        letterSpacing: '0.15px',
        lineHeight: '150%',
      },
    };
  },
  components: {
    MuiButton: {
      styleOverrides: {
        textSizeLarge: 15,
        textSizeMedium: 14,
        textSizeSmall: 13,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingRight: 24,
          paddingLeft: 24,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '24px !important',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          margin: '0',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.transparent,
          borderRadius: `${theme.spacing(2)} !important`,
          '&.Mui-expanded': {
            margin: '0 0',
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          alignItems: 'center',
          margin: 0,
        },
        root: ({ theme }) => ({
          minHeight: theme.spacing(5),
          '&.Mui-expanded': {
            minHeight: theme.spacing(5),
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 32,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 32,
          height: 48,
          paddingRight: 16,
        },
        input: {
          padding: '12px 16px',
          paddingRight: 0,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '8px 0',
          '& + &': {
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 56,
          height: 56,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: ({ theme }) => ({
          // TODO: eventually add color to palette / find a better solution (see also 'BottomNavigation.tsx')
          color: '#FFFFFFB3',
          justifyContent: 'flex-start',
          padding: 12,
          minWidth: "60px",
          whiteSpace: 'nowrap',
          '&.Mui-selected': {
            color: theme.palette.secondary.main,
            paddingTop: 12,
            '&::before': {
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '0px 0px 2px 2px',
              content: '""',
              display: 'block',
              height: 4,
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
            },
          },
        }),
      },
    },
  },
});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};
