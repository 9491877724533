import { Fragment, ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMediaQuery, useTheme } from '@mui/material';
import { MUI, LoadingSpinner } from 'ui';
import { Routes } from '../constants';
import { Header } from './Header';
import { Navigation } from './Navigation';
import { extractItemFromContentModules, getLocale } from '../lib/utils';
import { useJsApiLoader, LoadScriptProps } from '@react-google-maps/api';
import { useStaticTexts } from '../stores/useStaticTexts';
import { IHeader } from '../contentfulTypes';
import { fetchPageContent } from '../lib/contentful';
import { isEmpty } from 'lodash';
import { css, Global } from '@emotion/react';
import useStaticTextsQuery from '../hooks/useStaticTextsQuery';
import useSWR from 'swr';

type LayoutProps = {
  children: ReactNode;
};
const MAP_LIBRARY: LoadScriptProps['libraries'] = ['places'];

export function Layout({ children }: LayoutProps): JSX.Element {
  const { StaticTexts } = useStaticTexts();
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up('lg'));
  const [height, setHeight] = useState<number>(0);
  const updateHeight = () => setHeight(window.innerHeight);
  const locale = getLocale();
  const isMapOrFeedbackPage = location.pathname === Routes['Partner.Map'] || Routes['Feedback'];
  const [headerData, setHeaderData] = useState<IHeader>();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAP_API as string,
    libraries: MAP_LIBRARY,
    language: locale,
  });
  useStaticTextsQuery(locale);

  useEffect(() => {
    updateHeight();
    addEventListener('resize', updateHeight);
    return () => {
      removeEventListener('resize', updateHeight);
    };
  }, []);

  const [mounted, setMounted] = useState(false);
  const { data } = useSWR(mounted ? 'headerData_' + location.pathname : null, () =>
    fetchPageContent(location.pathname, locale),
  );

  useEffect(() => {
    if (typeof location.pathname === 'string') {
      setMounted(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (data) {
      setHeaderData(extractItemFromContentModules<IHeader>(data.contentModules, 'header'));
    }
  }, [data]);

  if (isEmpty(StaticTexts) || !isLoaded) return <LoadingSpinner />;

  return (
    <Fragment>
      <Global
        styles={css`
          html {
            hyphens: auto;
            word-break: break-word;
            overscroll-behavior: none;
          }

          #gatsby-focus-wrapper > div {
            display: flex;
            flex-direction: column;
            overflow: hidden auto;
            overscroll-behavior: none;
          }

          #gatsby-focus-wrapper > div > div {
            flex: 1;
            overflow: auto;
            margin: 0;
            padding: 0;
            width: auto;
            overscroll-behavior: none;
          }

          @media only screen and (min-width: 1020px) {
            #consultationIframe {
              height: 2100px;
            }
          }
        `}
      />
      <Helmet>
        <html lang={locale} />
        <title>{StaticTexts.Appname}</title>
      </Helmet>
      <MUI.Box id="layout" height={`${height}px`}>
        <MUI.Box
          id="scrollableDiv"
          flex={isMapOrFeedbackPage ? undefined : 1}
          display={isMapOrFeedbackPage ? 'flex' : undefined}
          flexDirection={isMapOrFeedbackPage ? 'column' : undefined}
          overflow="auto"
        >
          {breakpoint ? <Navigation breakpoint={breakpoint} /> : null}

          <Header
            headline={headerData?.fields.headline ?? ''}
            description={headerData?.fields.description}
          />
          <MUI.Box
            id="main-wrapper"
            component="main"
            height={isMapOrFeedbackPage ? '100%' : undefined}
            sx={{
              marginX: breakpoint ? '25%' : undefined,
            }}
          >
            {children}
          </MUI.Box>
        </MUI.Box>
        {location.pathname !== Routes['Kids'] && !breakpoint ? (
          <MUI.Box component="footer">
            <Navigation breakpoint={breakpoint} />
          </MUI.Box>
        ) : null}
      </MUI.Box>
    </Fragment>
  );
}
