import { IconButton as MUIIconButton, styled } from '@mui/material';
import type { IconButtonProps as MUIIconButtonProps } from '@mui/material';

export type IconButtonProps = MUIIconButtonProps & {
  /**
   * Custom props
   */
};

const _IconButton = (props: IconButtonProps) => {
  return <MUIIconButton {...props} color="primary" />;
};

export const IconButton = styled(_IconButton)<IconButtonProps>(({ theme: { palette } }) => ({
  backgroundColor: palette.background.paper,
  height: 48,
  width: 48,
  ':hover': {
    backgroundColor: `${palette.background.paper} !important`,
  },
}));
