export class ChatWidgetApi {
  hide(): void {
    document.getElementById('header-wrapper')?.classList.add('hide');
    document.getElementById('chat-container')?.classList.add('hide');
    document.getElementById('main-wrapper')?.classList.add('stylingForChat');
  }

  show(): void {
    document.getElementById('header-wrapper')?.classList.remove('hide');
    document.getElementById('chat-container')?.classList.remove('hide');
    document.getElementById('main-wrapper')?.classList.remove('stylingForChat');
  }
}

window.ChatWidgetApi = new ChatWidgetApi();
