import { Dialog as MUIDialog, DialogContent as MUIDialogContent } from '@mui/material';
import type { DialogProps as MUIDialogProps } from '@mui/material';

export type DialogProps = MUIDialogProps;

export const Dialog = (props: DialogProps) => {
  return (
    <MUIDialog {...props} fullWidth color="white" sx={{ margin: '20px' }}>
      <MUIDialogContent>{props.children}</MUIDialogContent>
    </MUIDialog>
  );
};
