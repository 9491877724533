import { navigate } from 'gatsby';
import { BottomNavigation, MUI, padding } from 'ui';
import { getIcon, Routes } from '../constants';
import { useStaticTexts } from '../stores/useStaticTexts';

type NavigationType = {
  breakpoint: boolean;
};

export function Navigation({ breakpoint }: NavigationType): JSX.Element | null {
  const { StaticTexts } = useStaticTexts();

  const NAVIGATION_MAP = {
    '': 1,
    knowledge: 2,
    partner: 3,
    chat: 4,
    more: 5,
  }[location.pathname.split('/')[1]];

  const showHeader = () => {
    const header = document.getElementById('header-wrapper');
    if (header) {
      header.classList.remove('hide');
    }
  };

  const iconWrapper = (icon: JSX.Element | null) => {
    if (icon === null) return <></>;
    return (
      <MUI.Box
        sx={{
          position: breakpoint ? 'absolute' : undefined,
          left: breakpoint ? '0px' : undefined,
          top: breakpoint ? '7px' : undefined,
        }}
      >
        {icon}
      </MUI.Box>
    );
  };

  return (
    <BottomNavigation
      value={NAVIGATION_MAP}
      height={breakpoint ? padding.NavigationBarWeb : padding.NavigationBarApp}
      sx={{ justifyContent: breakpoint ? 'start' : undefined }}
    >
      {breakpoint ? (
        <MUI.Box
          sx={{
            display: 'flex',
            marginRight: (theme) => theme.spacing(5),
            marginLeft: (theme) => theme.spacing(2.5),
            marginY: (theme) => theme.spacing(1.5),
            alignItems: 'center',
          }}
        >
          <img src="/images/MUT_Logo_long.webp" alt="Logo" height="16px" />
        </MUI.Box>
      ) : null}
      <MUI.BottomNavigationAction
        sx={{ maxWidth: breakpoint ? '90px' : undefined }}
        label={StaticTexts.navigation_start}
        icon={iconWrapper(getIcon('Home'))}
        onClick={() => {
          navigate(Routes['Home']);
          window.ChatWidgetApi.show();
        }}
      />
      <MUI.BottomNavigationAction
        sx={{ maxWidth: breakpoint ? '100px' : undefined }}
        label={StaticTexts.navigation_knowledge}
        icon={iconWrapper(getIcon('LightbulbOutlined'))}
        onClick={() => {
          navigate(Routes['Knowledge']);
          showHeader();
          window.ChatWidgetApi.show();
        }}
      />
      <MUI.BottomNavigationAction
        sx={{ maxWidth: breakpoint ? '130px' : undefined }}
        label={StaticTexts.navigation_partner}
        icon={iconWrapper(getIcon('TravelExploreOutlined'))}
        onClick={() => {
          navigate(Routes['Partner']);
          showHeader();
          window.ChatWidgetApi.show();
        }}
      />
      <MUI.BottomNavigationAction
        sx={{ maxWidth: breakpoint ? '100px' : undefined }}
        label={StaticTexts.navigation_chat}
        icon={iconWrapper(getIcon('Chat'))}
        onClick={() => {
          navigate(Routes['Chat']);
          showHeader();
          window.ChatWidgetApi.show();
        }}
      />
      <MUI.BottomNavigationAction
        sx={{ maxWidth: breakpoint ? '80px' : undefined }}
        label={StaticTexts.navigation_more}
        icon={iconWrapper(getIcon('MoreVert'))}
        onClick={() => {
          navigate(Routes['More']);
          window.ChatWidgetApi.show();
        }}
      />
    </BottomNavigation>
  );
}
