import DialogActions from '@mui/material/DialogActions';
import { MUI } from 'ui';
import { Button } from '../Button/Button';
import { Dialog, DialogProps } from './Dialog';

export type ConfirmDialogProps = DialogProps & {
  title: string;
  description?: string;
  icon?: string;
  buttonText?: string;
  cancelText?: string;
  setOpen: (open: boolean) => void;
  open: boolean;
  onConfirm: () => void;
};

export const ConfirmDialog = ({
  title,
  description,
  icon,
  buttonText,
  cancelText,
  setOpen,
  open,
  onConfirm,
}: ConfirmDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <MUI.Typography variant="h4" width="100%">
        {title}
      </MUI.Typography>

      <MUI.Typography marginTop={({ spacing }) => spacing(1)} variant="body1">
        {description}
      </MUI.Typography>
      <MUI.Box
        marginTop={({ spacing }) => spacing(3)}
        marginBottom={({ spacing }) => spacing(2)}
        display="flex"
        alignItems="center"
      >
        <MUI.Box src={`/images/kids/${icon}.webp`} component={'img'} height={104} margin="auto" />
      </MUI.Box>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: ({ spacing }) => spacing(1),
        }}
      >
        <Button
          color="primary"
          size="large"
          variant="contained"
          sx={{
            marginLeft: ({ spacing }) => spacing(4),
            marginRight: ({ spacing }) => spacing(4),
            width: '100%',
          }}
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          {buttonText}
        </Button>
        <Button variant="text" onClick={() => setOpen(false)} color="primary">
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
