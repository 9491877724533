import type { PageContent, StaticTexts } from './../types';
import { EmptyPageLayoutProps } from './../components/EmptyPageLayout';
import {
  IEmptyPageFields,
  IExpertItemNew,
  IFaqItemNew,
  ILinkListFields,
  ILinkListItem,
  IStaticText,
  ITargetGroupTag,
  IThemeTagExpert,
  IThemeTagFaq,
} from './../contentfulTypes';
import { getLocale } from './utils';
import { createClient } from 'contentful';
import { ILayoutFields, LOCALE_CODE } from '../contentfulTypes';
import { ListItem, ListProps } from '../components/List';
import { compact, concat } from 'lodash';
import { Expert } from '../components/Expert';
import { FAQAccordiontPanelLink } from '../components/FAQ/FAQAccordion';
import { FAQItem, ThemeList, TargetGroupList } from '../stores/useStore';

/**
 * Number of nested levels to include for Contentful response containing links to other entities.
 * See https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/links/retrieval-of-linked-items for more details
 */
const CONTENTFUL_INCLUSION_DEPTH = 5 as const;
const CONTENTFUL_ITEMS_PER_REQUEST_LIMIT = 1000;

// create Contentful SDK client with needed credentials
const client = createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE ?? '',
  accessToken: process.env.GATSBY_CONTENTFUL_CONTENT_DELIVERY_API_ACCESS_TOKEN ?? '',
});

/**
 * Fetch content with metadata from Contentful
 * @param contentType id of contentModel in contentful
 * @param locale language in which the content is fetched
 * @param filterOptions additional filterOptions e.g. `{ order: 'fields.title' }`
 * @returns array of fetched contentType with metadata
 */
export const fetchContentWithMetadata = async <T>(
  contentType: string,
  locale: string,
  filterOptions?: Record<string, string>,
): Promise<Array<T>> => {
  let numberOfItems = undefined;
  let skip = 0;
  let items: Array<T> = [];

  while (numberOfItems === undefined || numberOfItems - skip > 0) {
    const entries = await client.getEntries<T>({
      content_type: contentType,
      locale,
      include: CONTENTFUL_INCLUSION_DEPTH,
      limit: CONTENTFUL_ITEMS_PER_REQUEST_LIMIT,
      skip,
      ...filterOptions,
    });

    //TODO: fix typing
    items = [...items, ...(entries.items as Array<T>)];
    skip = skip + entries.limit;
    numberOfItems = entries.total;
  }
  return items;
};

/**
 * Fetch pageContent of one Layout/Page by slug (without layout metaData)
 * @param slug of specific Layout/Page
 * @param locale language in which the content is fetched
 * @returns array of fetched ILayoutFields (without metadata)
 */
export const fetchPageContent = async (
  slug: string,
  locale: string,
): Promise<ILayoutFields | null> => {
  const pageData = (
    await client.getEntries<ILayoutFields>({
      content_type: 'layout',
      'fields.slug': slug,
      locale,
      include: CONTENTFUL_INCLUSION_DEPTH,
    })
  ).items[0];

  if (pageData === undefined) return null;

  return pageData.fields;
};

/**
 * Fetch static texts in a specific language
 * @param locale language in which the content is fetched
 * @returns static texts as object
 */
export const fetchStaticTexts = async (locale: LOCALE_CODE): Promise<StaticTexts> => {
  const staticTexts = (await fetchContentWithMetadata<IStaticText>('staticText', locale)).map(
    (item) => item.fields,
  );

  let StaticTexts: StaticTexts = {};

  for (const item of staticTexts) {
    StaticTexts = {
      ...StaticTexts,
      [item.key]: item.value,
    };
  }

  return StaticTexts;
};

/**
 * Fetch pageContent of one Layout/Page by slug
 * @param slug of specific Layout/Page
 * @returns array of fetched ILayoutFields (without metadata)
 */
export const fetchContentFromContentful = async (slug?: string): Promise<PageContent> => {
  const locale = getLocale();
  const content = slug ? await fetchPageContent(slug, locale) : undefined;
  return { pageContent: content ?? undefined };
};

//--- Object-Mapping Methods ---//

export const importFaqData = (
  contentFaqItemList: IFaqItemNew[],
  contentTargetGroupList: ITargetGroupTag[],
  contentThemeList: IThemeTagFaq[],
  storeThemeList: ThemeList,
  storeTargetGroupList: TargetGroupList,
  setItems: (items: FAQItem[]) => void,
  settargetGroupList: (list: TargetGroupList) => void,
  setthemeList: (list: ThemeList) => void,
): void => {
  const items: Array<FAQItem> = [];
  const themeList: ThemeList = [];
  const targetGroupList: TargetGroupList = [];

  contentFaqItemList.forEach(({ fields: item, sys: { id } }) => {
    items.push({
      summary: item.summary,
      id: id,
      contentful_id: id,
      description: item.description,
      isFrequentlyAsked: item.isFrequentlyAskedQuestion,
      links: compact(
        concat<FAQAccordiontPanelLink | null>(
          item.link01Href || item.link01Label
            ? {
                href: item.link01Href ?? '',
                text: item.link01Label,
                target: '_blank',
              }
            : null,
          item.link02Href || item.link02Label
            ? {
                href: item.link02Href ?? '',
                text: item.link02Label,
                target: '_blank',
              }
            : null,
          item.link03Href || item.link03Label
            ? {
                href: item.link03Href ?? '',
                text: item.link03Label,
                target: '_blank',
              }
            : null,
          item.video01 !== ''
            ? {
                href: '',
                video: item.video01,
              }
            : null,
          item.video02 !== ''
            ? {
                href: '',
                video: item.video02,
              }
            : null,
          item.video03 !== ''
            ? {
                href: '',
                video: item.video03,
              }
            : null,
        ),
      ),
      tag: item.tagTheme ? item.tagTheme.map((item) => item.fields.title) : [],
      targetGroup: item.targetGroup ? item.targetGroup.map((item) => item.fields.title) : [],
    });
  });

  contentThemeList.map((tag) => {
    const item = storeThemeList.find((item) => item.id === tag.sys.id);
    if (item) {
      themeList.push(item);
    } else {
      themeList.push({ id: tag.sys.id, checked: false, ...tag.fields });
    }
  });

  contentTargetGroupList.map((targetGroup) => {
    const item = storeTargetGroupList.find((item) => item.id === targetGroup.sys.id);
    if (item) {
      targetGroupList.push(item);
    } else {
      targetGroupList.push({ id: targetGroup.sys.id, checked: false, ...targetGroup.fields });
    }
  });

  setthemeList(themeList);
  settargetGroupList(targetGroupList);
  setItems(items);
};

export const importMapData = (
  contentMapItemList: IExpertItemNew[],
  contentThemeList: IThemeTagExpert[],
  storeThemeList: ThemeList,
  setItems: (items: Expert[]) => void,
  setthemeList: (list: ThemeList) => void,
): void => {
  const items: Array<Expert> = [];
  const themeList: ThemeList = [];

  contentMapItemList.map(({ sys: { id }, fields: expert }) => {
    items.push({
      ...expert,
      id: id,
      contentful_id: id,
      category: expert.category?.fields.title
        ? { title: expert.category?.fields.title }
        : undefined,
      address: {
        streetAndNumber: expert.streetAndNumber,
        city: expert.city,
        zipCode: expert.zipCode,
        country: expert.country,
      },
      location: {
        lat: expert.location.lat,
        lng: expert.location.lon,
      },
    });
  });

  contentThemeList.map((tag) => {
    const item = storeThemeList.find((item) => item.id === tag.sys.id);
    if (item) {
      themeList.push(item);
    } else {
      themeList.push({ id: tag.sys.id, checked: false, ...tag.fields });
    }
  });

  setthemeList(themeList);
  setItems(items);
};

export const mapLinkListToListItemType = ({
  sys: { id },
  fields: { ...fields },
}: ILinkListItem): ListItem => ({
  id: id,
  ...fields,
  link: fields.link?.fields,
});

export const mapThemeTagToListItemType = ({
  sys: { id },
  fields: { ...fields },
}: IThemeTagExpert): ListItem => ({
  id: id,
  ...fields,
});

export const mapListProps = (list: ILinkListFields | undefined): ListProps => ({
  ...list,
  items: list?.items?.map(mapLinkListToListItemType) ?? [],
});

export const mapEmptyLayoutProps = (content?: IEmptyPageFields): EmptyPageLayoutProps => ({
  headline: content?.headline,
  image: {
    publicUrl: 'https:' + content?.image?.fields.file.url,
    title: content?.image?.fields.title,
  },
  link: {
    href: content?.link?.fields.href,
    label: content?.link?.fields.label,
  },
  text: {
    text: content?.text,
  },
});
