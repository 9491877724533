// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment } from 'react';
import { ThemeProvider, MUI } from 'ui';
import type { GatsbyBrowser } from 'gatsby';
import { Layout } from './src/components/Layout';
import './src/components/ChatWidgetApi/ChatWidgetApi';
import { SWRConfig } from 'swr';
import { App } from '@capacitor/app';

/**
 * Creates a new cache and reads (on app init) and writes (on app visibility change to background)
 * the cached data to the localStorage
 * @returns {Map} swrCache
 */
const localStorageProvider = () => {
  let swrCache = new Map();
  if (typeof window !== 'undefined') {
    // Load localStorage data to cache
    console.log('Write LocalStorage to cache');

    swrCache = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'));

    // When page is "unloaded" cache data is stored in localStorage
    App.addListener('appStateChange', ({ isActive }) => {
      // if isActive is false which means App is in background (or closed)
      if (!isActive) {
        // store the cache data to localStorage
        const appCache = JSON.stringify(Array.from(swrCache.entries()));
        localStorage.setItem('app-cache', appCache);
        console.log('Cache is stored to LocalStorage.');
      }
    });
  }
  return swrCache;
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  //TODO: fix typing
  const LayoutWrapper = (element.type as any).Layout ? Layout : Fragment;

  return (
    <SWRConfig
      value={{
        provider: localStorageProvider,
      }}
    >
      <ThemeProvider>
        <MUI.CssBaseline />
        <LayoutWrapper>{element}</LayoutWrapper>
      </ThemeProvider>
    </SWRConfig>
  );
};
