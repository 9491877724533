import {
  FormControlLabel as MUIFormControlLabel,
  Checkbox as MUICheckbox,
  Typography,
  styled,
} from '@mui/material';
import type { CheckboxProps as MUICheckboxProps } from '@mui/material';

export type CheckboxProps = MUICheckboxProps & {
  /**
   * Custom props
   */
  children: string;
};

const _Checkbox = ({ children, ...props }: CheckboxProps) => {
  return (
    <MUIFormControlLabel
      control={<MUICheckbox {...props} />}
      label={<Typography variant="body1">{children} </Typography>}
    />
  );
};

export const Checkbox = styled(_Checkbox)<CheckboxProps>(({ theme: { spacing } }) => ({
  marginRight: spacing(1.5),
}));
