import { ReactNode } from 'react';
import { Grid, MUI } from 'ui';

type HeaderBodyProps = {
  children?: ReactNode;
  marginRight?: string;
};

export function HeaderBody({ children, marginRight }: HeaderBodyProps): JSX.Element {
  return (
    <Grid container>
      <Grid item>
        <MUI.Typography
          variant="body2"
          color="text.darkPrimary"
          marginRight={marginRight}
          zIndex={1}
          position="relative"
        >
          {children}
        </MUI.Typography>
      </Grid>
    </Grid>
  );
}
