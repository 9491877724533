import useSWR from 'swr';
import { useEffect } from 'react';
import { fetchStaticTexts } from '../lib/contentful';
import type { LOCALE_CODE } from '../contentfulTypes';
import { useStaticTexts } from '../stores/useStaticTexts';

export default (locale: LOCALE_CODE): void => {
  const { setStaticTexts } = useStaticTexts();

  const { data } = useSWR('staticTexts', () => fetchStaticTexts(locale));

  useEffect(() => {
    if (data) setStaticTexts(data);
  }, [data]);
};
