import { navigate } from 'gatsby';
import { Icon, MUI } from 'ui';
import { FilterBar } from '../FilterBar';
import { HeaderBody, HeaderIntro, HeaderImage, HeaderWrapper } from '.';
import { useMapStore, useFaqStore } from '../../stores/useStore';
import { KidsZoneHeader } from './KidsZoneHeader';
import { useStaticTexts } from '../../stores/useStaticTexts';

type BackIconProps = {
  clickCallback?: () => void;
};

const BackIcon = ({ clickCallback }: BackIconProps): JSX.Element => {
  return (
    <Icon.ArrowBack
      sx={{
        color: ({ palette }) => palette.text.darkPrimary,
        ':hover': {
          cursor: 'pointer',
        },
        alignSelf: 'flex-start',
      }}
      onClick={() => {
        clickCallback?.();
        navigate(-1);
      }}
    ></Icon.ArrowBack>
  );
};

export type HeaderProps = {
  headline: string;
  description?: string;
};

export function Header({ headline, description }: HeaderProps): JSX.Element {
  const { applyFilters: applyFaqFilters } = useFaqStore();
  const { applyFilters: applyMapFilters } = useMapStore();
  const { StaticTexts } = useStaticTexts();

  // TODO: refactor switch-case: remove/refactor duplicated definitions of identical headers
  switch (location.pathname) {
    case '/':
      return (
        <HeaderWrapper>
          <MUI.Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            marginBottom={(theme) => theme.spacing(7)}
          >
            <MUI.Typography marginBottom={0.5} variant="h4" color="text.darkPrimary">
              {headline}
            </MUI.Typography>
            <MUI.Typography variant="subtitle2" component="h5" color="text.darkPrimary">
              {description}
            </MUI.Typography>
          </MUI.Box>
        </HeaderWrapper>
      );

    case '/knowledge':
      return (
        <HeaderWrapper>
          <HeaderImage alt="Ein Bild" />
          <HeaderIntro headline={headline} />
          <HeaderBody marginRight="85px">{description}</HeaderBody>

          <FilterBar store={useFaqStore} />
        </HeaderWrapper>
      );
    case '/partner':
      return (
        <HeaderWrapper>
          <HeaderImage alt="Ein Bild" />
          <HeaderIntro headline={headline} />
          <HeaderBody marginRight="145px">{description}</HeaderBody>
          <FilterBar store={useMapStore} />
        </HeaderWrapper>
      );

    case '/knowledge/faq':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.faqList_headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
          <FilterBar store={useFaqStore} />
        </HeaderWrapper>
      );
    case '/partner/map':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.mapView_Headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
          <FilterBar store={useMapStore} />
        </HeaderWrapper>
      );
    case '/partner/expertList':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.expertsList_headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
          <FilterBar store={useMapStore} />
        </HeaderWrapper>
      );

    case '/chat':
      return (
        <HeaderWrapper>
          <HeaderImage alt="Ein Bild" />
          <HeaderIntro headline={headline} />
          <HeaderBody marginRight="150px">{description}</HeaderBody>
        </HeaderWrapper>
      );
    case '/more':
      return (
        <HeaderWrapper>
          <HeaderImage alt="Ein Bild" />
          <HeaderIntro headline={headline} />
          <HeaderBody marginRight="140px">{description}</HeaderBody>
        </HeaderWrapper>
      );
    case '/more/consultation':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.consultation_headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );
    case '/more/contact':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.contact_headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );
    case '/more/hotline':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.hotline_headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );
    case '/more/feedback':
    case '/feedback':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.feedback_headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );
    case '/more/privacy':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.privacy_Headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );
    case '/more/language':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.language_headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );
    case '/more/sponsor':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.supporter_headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );
    case '/more/imprint':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.imprint_Headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );
    case '/knowledge/themeSelect':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.theme}
            headlineVariant="h6"
            icon={<BackIcon clickCallback={applyFaqFilters} />}
          />
        </HeaderWrapper>
      );
    case '/knowledge/targetGroupSelect':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.targetGroup}
            headlineVariant="h6"
            icon={<BackIcon clickCallback={applyFaqFilters} />}
          />
        </HeaderWrapper>
      );
    case '/partner/categorySelect':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.categories_headline}
            headlineVariant="h6"
            icon={<BackIcon clickCallback={applyMapFilters} />}
          />
        </HeaderWrapper>
      );
    case '/partner/expertDetails':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.contactInformation_headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );
    case '/partner/radiusSelect':
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.radiusSearch_Headline}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );

    case '/knowledge/filter': {
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.filter_headline}
            headlineVariant="h6"
            icon={<BackIcon clickCallback={applyFaqFilters} />}
          />
        </HeaderWrapper>
      );
    }
    case '/partner/mapFilter': {
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts.filter_headline}
            headlineVariant="h6"
            icon={<BackIcon clickCallback={() => applyMapFilters()} />}
          />
        </HeaderWrapper>
      );
    }
    case '/kids': {
      return (
        <HeaderWrapper backgroundImage='url("/images/kids/header.webp")'>
          <KidsZoneHeader />
        </HeaderWrapper>
      );
    }
    default: {
      const slug = location.pathname.split('/')[location.pathname.split('/').length - 1];
      return (
        <HeaderWrapper>
          <HeaderIntro
            headline={StaticTexts[`${slug}PageHeader`]}
            headlineVariant="h6"
            icon={<BackIcon />}
          />
        </HeaderWrapper>
      );
    }
  }
}
