import { TextField as MUITextField, styled } from '@mui/material';
import type { TextFieldProps as MUITextFieldProps } from '@mui/material';
import { Cancel, LocationSearchingOutlined, Search } from '@mui/icons-material';
import { Fragment } from 'react';
import './SearchField.css';

export type SearchFieldProps = MUITextFieldProps & {
  /**
   * Custom props
   */
  handleSearch: () => void;
  clearSearchQuery?: () => void;
  getLocation?: () => void;
  location?: boolean;
};

const _SearchField = ({
  handleSearch,
  clearSearchQuery,
  getLocation,
  location,
  ...props
}: SearchFieldProps) => {
  return (
    <MUITextField
      {...props}
      onKeyPress={({ key }) => {
        if (key === 'Enter') {
          handleSearch();
        }
      }}
      InputProps={{
        endAdornment: (
          <Fragment>
            {props.value === '' ? null : (
              <Cancel color="primary" onClick={clearSearchQuery} sx={{ cursor: 'pointer' }} />
            )}
            {location ? (
              <LocationSearchingOutlined
                onClick={getLocation}
                sx={{ marginLeft: '10px', marginRight: '10px', cursor: 'pointer' }}
              />
            ) : null}
            <Search color="primary" onClick={handleSearch} sx={{ cursor: 'pointer' }} />
          </Fragment>
        ),
      }}
      type="search"
      variant="outlined"
    />
  );
};

export const SearchField = styled(_SearchField)<SearchFieldProps>(({ theme: { palette } }) => ({
  backgroundColor: palette.background.paper,
  borderColor: palette.primary.main,
}));
