import Slider from 'react-slick';
import { MUI } from 'ui';
import { ListItem } from '../../../../webapp/src/components/List';
import { IKidsCategoriesFields } from '../../../../webapp/src/contentfulTypes';
import { VideoProps } from '../Video/Video';
import './Carousel.css';

export type KidsListItem = ListItem & {
  category: IKidsCategoriesFields['title'];
  video: VideoProps;
};

type CarouselProps = {
  children: React.ReactNode;
};

export const Carousel = ({ children }: CarouselProps) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    useCss: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ],
  };
  return (
    <MUI.Box sx={{ marginRight: '14px' }}>
      <Slider {...settings}>{children}</Slider>
    </MUI.Box>
  );
};
