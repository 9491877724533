import { navigate } from 'gatsby';
import { ChangeEvent } from 'react';
import { MUI, Icon, IconButton, SearchField, SearchFieldProps } from 'ui';
import { StoreState, FAQItem } from '../stores/useStore';
import { Routes } from '../constants';
import { Expert } from '../components/Expert';
import { UseBoundStore, StoreApi } from 'zustand';
import { tracking } from '../lib/trackings';
import { useStaticTexts } from '../stores/useStaticTexts';

type FilterBarProps<T extends FAQItem | Expert> = {
  store: UseBoundStore<StoreApi<StoreState<T>>>;
};

export function FilterBar<T extends FAQItem | Expert>({ store }: FilterBarProps<T>): JSX.Element {
  const route = location.pathname;
  const { StaticTexts } = useStaticTexts();
  const { filterBySearch, searchQuery, setSearchQuery, getFilteredItemsResult } = store();

  const handleChangeSearch: SearchFieldProps['onChange'] = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(target.value);
    filterBySearch(target.value);
  };

  const handleSearch: SearchFieldProps['handleSearch'] = () => {
    if (!searchQuery) {
      return false;
    }

    switch (route) {
      case Routes['Knowledge']:
      case Routes['Knowledge.FAQ']: {
        tracking.track('knowledge_search', {
          search: {
            search_term: searchQuery,
            number_of_results: getFilteredItemsResult().length,
          },
        });
        navigate(Routes['Knowledge.FAQ']);
        break;
      }
      case Routes['Partner']:
      case Routes['Partner.Map']:
      case Routes['Partner.ExpertList']: {
        tracking.track('partner_search', {
          search: {
            search_term: searchQuery,
            number_of_results: getFilteredItemsResult().length,
          },
        });
        navigate(Routes['Partner.ExpertList']);
        break;
      }
    }
  };

  const clearSearchQuery: SearchFieldProps['clearSearchQuery'] = () => {
    setSearchQuery('');
    filterBySearch('');
  };

  return (
    <MUI.Box display="flex" gap={1.5} marginTop={2} width="100%">
      <SearchField
        fullWidth
        placeholder={StaticTexts.searchPlaceholder}
        onChange={handleChangeSearch}
        handleSearch={handleSearch}
        value={searchQuery}
        clearSearchQuery={clearSearchQuery}
      />

      {route === Routes['Partner'] ? (
        <IconButton onClick={() => navigate(Routes['Partner.Map'])} aria-label="Partner - Map">
          <Icon.LocationOn />
        </IconButton>
      ) : null}
      {route === Routes['Partner.Map'] ? (
        <IconButton
          color="primary"
          onClick={() => navigate(Routes['Partner.ExpertList'])}
          aria-label="Partner - List"
        >
          <Icon.List />
        </IconButton>
      ) : null}
      {route === Routes['Partner.ExpertList'] ? (
        <IconButton
          color="primary"
          onClick={() => navigate(Routes['Partner.Map'])}
          aria-label="Partner - Map"
        >
          <Icon.LocationOn />
        </IconButton>
      ) : null}
    </MUI.Box>
  );
}
