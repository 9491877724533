import { Button, Icon, MUI } from 'ui';
import { navigate } from 'gatsby';
import { Routes } from '../../constants';
import { ConfirmDialog } from 'ui/src/components/Dialog/ConfirmationDialog';
import { useState } from 'react';
import { useStaticTexts } from '../../stores/useStaticTexts';

export const KidsZoneHeader = (): JSX.Element => {
  const { StaticTexts } = useStaticTexts();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  return (
    <MUI.Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ConfirmDialog
        title={StaticTexts.exitKidsZone_Headline}
        description={StaticTexts.exitKidsZone_Description}
        buttonText={StaticTexts.exitKidsZone_ButtonText}
        cancelText={StaticTexts.exitKidsZone_cancelText}
        icon="emoji_waving"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => {
          navigate(Routes['Home']);
        }}
      />
      <MUI.Typography
        variant="h2"
        color="text.darkPrimary"
        sx={{
          marginBottom: ({ spacing }) => spacing(1.75),
          textShadow: ({ palette }) => palette.background.textShadow,
        }}
      >
        {StaticTexts.headerTitle}
      </MUI.Typography>
      <Button
        color="primary"
        size="large"
        variant="contained"
        sx={{ marginBottom: ({ spacing }) => spacing(3) }}
        onClick={() => {
          setConfirmOpen(true);
        }}
      >
        <Icon.ArrowBack sx={{ color: ({ palette }) => palette.text.darkPrimary }}></Icon.ArrowBack>
        &nbsp;{StaticTexts.exitKidsZone_ButtonText}
      </Button>
    </MUI.Box>
  );
};
