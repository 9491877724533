import { TypographyProps } from '@mui/material';
import { ReactNode } from 'react';
import { MUI } from 'ui';
import { useStaticTexts } from '../../stores/useStaticTexts';
import { EmergencyContactButton } from '../Buttons/EmergencyButton';

type HeaderIntroProps = {
  headline: string;
  icon?: ReactNode;
  headlineVariant?: TypographyProps['variant'];
};

export function HeaderIntro({
  headline,
  icon = null,
  headlineVariant = 'h4',
}: HeaderIntroProps): JSX.Element {
  const { StaticTexts } = useStaticTexts();
  return (
    <MUI.Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
    >
      {icon}
      <MUI.Typography
        sx={{ marginLeft: ({ spacing }) => (icon ? spacing(4) : 0) }}
        variant={headlineVariant}
        color="text.darkPrimary"
      >
        {headline}
      </MUI.Typography>
      <EmergencyContactButton sx={{ marginLeft: 'auto' }} size="small">
        {StaticTexts.emergencyCallButtonText}
      </EmergencyContactButton>
    </MUI.Box>
  );
}
