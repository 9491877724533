//TODO: name needs to be changed to new Appname
export const savedBookmarksKey = 'App24StorageBookmarks87963487694';
export const savedExpertsKey = 'App24StorageExperts9872987234';

export const storeDeleteDataToLocalStorage = (key: string, itemID: string): void => {
  if (typeof Storage !== 'undefined') {
    const data: Array<string> = readDataFromLocalStorage(key);

    let updatedData;

    //check if id is already saved, if yes -> delete it from storage, if not -> save it
    if (data.includes(itemID)) {
      updatedData = data.filter((id) => itemID !== id);
    } else {
      updatedData = data.concat(itemID);
    }

    localStorage.setItem(key, JSON.stringify(updatedData));
  } else {
    //TODO: error handling (if necessary?)
  }
};

export const readDataFromLocalStorage = (key: string): Array<string> => {
  if (typeof Storage !== 'undefined') {
    const data = localStorage.getItem(key);

    if (data) {
      return JSON.parse(data);
    }
  } else {
    //TODO: error handling (if necessary?)
  }
  return [];
};
