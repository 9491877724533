import { BoxProps, styled } from '@mui/material';
import { MUI } from 'ui';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IKidsItemNewFields } from '../../../../webapp/src/contentfulTypes';

type CarouselSlideProps = BoxProps & Pick<IKidsItemNewFields, 'title'>;

const _CarouselSlide = ({ title, ...props }: CarouselSlideProps) => {
  return (
    <MUI.Box {...props}>
      <MUI.Typography
        color="text.darkPrimary"
        variant="subtitle1"
        padding={({ spacing }) => `${spacing(2)} ${spacing(3)} ${spacing(1.5)}`}
      >
        {title}
      </MUI.Typography>
      {props.children}
    </MUI.Box>
  );
};

export const CarouselSlide = styled(_CarouselSlide)<CarouselSlideProps>(({ theme }) => ({
  display: 'block !important',
  width: 'auto !important',
  backgroundColor: theme.palette.background.kidsSliderBackground,
  boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
  marginRight: '10px',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  padding: 0,
  height: 'auto',
}));
