import * as Icons from '@mui/icons-material';

export const MUT_CONFIG_KEY_LOCALSTORAGE = 'mut-config';

export enum Routes {
  Home = '/',

  Knowledge = '/knowledge',
  'Knowledge.FAQ' = '/knowledge/faq',
  'Knowledge.Filter' = '/knowledge/filter',
  'Knowledge.TargetGroupSelect' = '/knowledge/targetGroupSelect',
  'Knowledge.ThemeSelect' = '/knowledge/themeSelect',

  'Partner' = '/partner',
  'Partner.Map' = '/partner/map',
  'Partner.MapFilter' = '/partner/mapFilter',
  'Partner.ExpertList' = '/partner/expertList',
  'Partner.ExpertDetails' = '/partner/expertDetails',
  'Partner.CategorySelect' = '/partner/categorySelect',
  'Partner.RadiusSelect' = '/partner/radiusSelect',

  'Chat' = '/chat',

  'More' = '/more',
  'More.Consultation' = '/more/consultation',
  'More.Contact' = '/more/contact',
  'More.Hotline' = '/more/hotline',
  'More.Feedback' = '/more/feedback',
  'More.Language' = '/more/language',
  'More.Sponsor' = '/more/sponsor',
  'EmptyPage.Team' = '/emptyPage/team',

  'Feedback' = '/feedback',

  'Kids' = '/kids',
}

export enum ContentTypeIdentifier {
  EmptyPage = 'emptyPage',
  StaticText = 'staticText',
  KidsItems = 'kidsItemNew',
  FaqItems = 'faqItemNew',
  ThemeTagFaq = 'themeTagFaq',
  TargetGroup = 'targetGroupTag',
  ExpertItems = 'expertItemNew',
  ThemeTagExpert = 'themeTagExpert',
}

export enum URL_HashParameters {
  FrequentlyAskedQuestions = '#filteredFAQ',
  SavedQuestions = '#filteredSavedQuestion',
  PeopleSeekingHelp = '#filteredPeopleSeekingHelp',
  Relatives = '#filteredRelatives',
  SavedContacts = '#filteredSavedContacts',
  ExpertsInArea = '#filteredExpertsInArea',
}

const ICON_MAP = {
  Home: <Icons.Home />,
  Mail: <Icons.Mail />,
  Event: <Icons.Event />,
  Chat: <Icons.Chat />,
  MoreVert: <Icons.MoreVert />,
  Lightbulb: <Icons.Lightbulb />,
  Psychology: <Icons.Psychology />,
  ChatBubbleOutline: <Icons.ChatBubbleOutline />,
  LiveHelpOutlined: <Icons.LiveHelpOutlined />,
  PersonOutlineOutlined: <Icons.PersonOutlineOutlined />,
  PeopleAltOutlined: <Icons.PeopleAltOutlined />,
  LightbulbOutlined: <Icons.LightbulbOutlined />,
  TravelExploreOutlined: <Icons.TravelExploreOutlined />,
  PhoneInTalk: <Icons.PhoneInTalk />,
  Fax: <Icons.PrintOutlined />,
  MailOutlineOutlined: <Icons.MailOutlineOutlined />,
  Website: <Icons.PublicOutlined />,
} as const;

export const getIcon = (identifier: string | undefined): JSX.Element | null => {
  return identifier ? ICON_MAP[identifier as keyof typeof ICON_MAP] : null;
};
