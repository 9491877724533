import { Box as MUIBox } from '@mui/material';
import { useState } from 'react';
import { Icon } from 'ui';

export type VideoProps = {
  source: string;
  title?: string;
};

export function Video({ source }: VideoProps): JSX.Element {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <MUIBox
      display="flex"
      width="100%"
      sx={{
        aspectRatio: '16/9',
      }}
    >
      {isPlaying ? (
        <iframe
          allow="accelerometer; fullscreen; autoplay; encrypted-media;"
          src={`https://www.youtube.com/embed/${source}?autoplay=1&;enablejsapi=1`}
          title={`Video: ${source}`}
          frameBorder="none"
          width="100%"
          height="100%"
        />
      ) : (
        <MUIBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          onClick={() => setIsPlaying(true)}
        >
          <Icon.PlayCircleOutlined sx={{ position: 'absolute', fontSize: '5em', color: 'white' }} />
          <img
            src={`https://img.youtube.com/vi/${source}/mqdefault.jpg`}
            alt={`Video: ${source}`}
            width="100%"
            height="100%"
          />
        </MUIBox>
      )}
    </MUIBox>
  );
}
