import { Slider as MUISlider } from '@mui/material';
import type { SliderProps } from '@mui/material';

export const Slider = (props: SliderProps) => (
  <MUISlider
    {...props}
    color="primary"
    min={0}
    valueLabelDisplay="auto"
    valueLabelFormat={(value) => `${value} km`}
  />
);
