import { MUI } from 'ui';

type HeaderImageProps = {
  alt: string;
};

export function HeaderImage(props: HeaderImageProps): JSX.Element {
  return (
    <MUI.Box
      {...props}
      src={`/${location.pathname.replace('/', '')}.webp`}
      component={'img'}
      sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
      }}
    />
  );
}
