import { Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { MUI } from '../../..';
import { LOCALE_CODE } from '../../../../webapp/src/contentfulTypes';
import { getLocale } from '../../../../webapp/src/lib/utils';
import '../LoadingSpinner/spinner.css';
import StaticLoadingSpinnerText from '../LoadingSpinner/StaticLoadingSpinnerTexts.json';

type LoadingSpinnerProps = {
  small?: boolean;
  texts?: typeof StaticLoadingSpinnerText;
};
export const LoadingSpinner = ({
  small = false,
  texts = StaticLoadingSpinnerText,
}: LoadingSpinnerProps) => {
  const [locale, setLocale] = useState<LOCALE_CODE>('de-DE');
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setLocale(getLocale());
    const timeout = setTimeout(() => setError(true), 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <MUI.Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: ({ spacing }) => (small ? spacing(0) : spacing(3)),
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: ({ spacing }) => (small ? spacing(3) : spacing(6)),
        marginBottom: ({ spacing }) => (small ? spacing(3) : spacing(6)),
      }}
    >
      <div className="spinner-container">
        <div className="lds-spinner" style={{ transform: `scale(${small ? 0.5 : 1})` }}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {small ? (
        <Typography
          variant="subtitle2"
          color="text.primary"
          sx={{ whiteSpace: 'pre-wrap' }}
          align="center"
        >
          {texts[locale].loadingList}
        </Typography>
      ) : (
        <Fragment>
          <MUI.Container>
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ whiteSpace: 'pre-wrap' }}
              align="center"
            >
              {texts[locale].loading}
            </Typography>
          </MUI.Container>
          <MUI.Container>
            {error ? (
              <Typography
                variant="h6"
                color="text.primary"
                sx={{ whiteSpace: 'pre-wrap' }}
                align="center"
              >
                {texts[locale].error}
              </Typography>
            ) : null}
          </MUI.Container>
        </Fragment>
      )}
    </MUI.Box>
  );
};
