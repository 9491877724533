exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chat-index-tsx": () => import("./../../../src/pages/chat/index.tsx" /* webpackChunkName: "component---src-pages-chat-index-tsx" */),
  "component---src-pages-empty-page-tsx": () => import("./../../../src/pages/emptyPage.tsx" /* webpackChunkName: "component---src-pages-empty-page-tsx" */),
  "component---src-pages-feedback-index-tsx": () => import("./../../../src/pages/feedback/index.tsx" /* webpackChunkName: "component---src-pages-feedback-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kids-index-tsx": () => import("./../../../src/pages/kids/index.tsx" /* webpackChunkName: "component---src-pages-kids-index-tsx" */),
  "component---src-pages-knowledge-faq-tsx": () => import("./../../../src/pages/knowledge/faq.tsx" /* webpackChunkName: "component---src-pages-knowledge-faq-tsx" */),
  "component---src-pages-knowledge-filter-tsx": () => import("./../../../src/pages/knowledge/filter.tsx" /* webpackChunkName: "component---src-pages-knowledge-filter-tsx" */),
  "component---src-pages-knowledge-index-tsx": () => import("./../../../src/pages/knowledge/index.tsx" /* webpackChunkName: "component---src-pages-knowledge-index-tsx" */),
  "component---src-pages-knowledge-target-group-select-tsx": () => import("./../../../src/pages/knowledge/targetGroupSelect.tsx" /* webpackChunkName: "component---src-pages-knowledge-target-group-select-tsx" */),
  "component---src-pages-knowledge-theme-select-tsx": () => import("./../../../src/pages/knowledge/themeSelect.tsx" /* webpackChunkName: "component---src-pages-knowledge-theme-select-tsx" */),
  "component---src-pages-more-consultation-tsx": () => import("./../../../src/pages/more/consultation.tsx" /* webpackChunkName: "component---src-pages-more-consultation-tsx" */),
  "component---src-pages-more-contact-tsx": () => import("./../../../src/pages/more/contact.tsx" /* webpackChunkName: "component---src-pages-more-contact-tsx" */),
  "component---src-pages-more-feedback-tsx": () => import("./../../../src/pages/more/feedback.tsx" /* webpackChunkName: "component---src-pages-more-feedback-tsx" */),
  "component---src-pages-more-hotline-tsx": () => import("./../../../src/pages/more/hotline.tsx" /* webpackChunkName: "component---src-pages-more-hotline-tsx" */),
  "component---src-pages-more-index-tsx": () => import("./../../../src/pages/more/index.tsx" /* webpackChunkName: "component---src-pages-more-index-tsx" */),
  "component---src-pages-more-language-tsx": () => import("./../../../src/pages/more/language.tsx" /* webpackChunkName: "component---src-pages-more-language-tsx" */),
  "component---src-pages-more-sponsor-tsx": () => import("./../../../src/pages/more/sponsor.tsx" /* webpackChunkName: "component---src-pages-more-sponsor-tsx" */),
  "component---src-pages-partner-category-select-tsx": () => import("./../../../src/pages/partner/categorySelect.tsx" /* webpackChunkName: "component---src-pages-partner-category-select-tsx" */),
  "component---src-pages-partner-expert-details-tsx": () => import("./../../../src/pages/partner/expertDetails.tsx" /* webpackChunkName: "component---src-pages-partner-expert-details-tsx" */),
  "component---src-pages-partner-expert-list-tsx": () => import("./../../../src/pages/partner/expertList.tsx" /* webpackChunkName: "component---src-pages-partner-expert-list-tsx" */),
  "component---src-pages-partner-index-tsx": () => import("./../../../src/pages/partner/index.tsx" /* webpackChunkName: "component---src-pages-partner-index-tsx" */),
  "component---src-pages-partner-map-filter-tsx": () => import("./../../../src/pages/partner/mapFilter.tsx" /* webpackChunkName: "component---src-pages-partner-map-filter-tsx" */),
  "component---src-pages-partner-map-tsx": () => import("./../../../src/pages/partner/map.tsx" /* webpackChunkName: "component---src-pages-partner-map-tsx" */),
  "component---src-pages-partner-radius-select-tsx": () => import("./../../../src/pages/partner/radiusSelect.tsx" /* webpackChunkName: "component---src-pages-partner-radius-select-tsx" */)
}

