import { Button as MUIButton, styled } from '@mui/material';
import type { ButtonProps as MUIButtonProps } from '@mui/material';

export type ButtonProps = MUIButtonProps & {
  /**
   * Custom props
   */
};

const _Button = (props: ButtonProps) => {
  return <MUIButton {...props} />;
};

export const Button = styled(_Button)<ButtonProps>(() => ({
  borderRadius: '21px',
  minWidth: '110px',
  textTransform: 'none',
}));
