import { v4 as uuidv4 } from 'uuid';
import { PartialRecord } from '../types';
import { getMobileOperatingSystem } from './utils';

type TrackingEvent =
  | 'app_install'
  | 'app_open'
  | 'emergency_call'
  | 'knowledge_search'
  | 'partner_search'
  | 'partner_locate_me'
  | 'partner_filter_radius'
  | 'chat_open'
  | 'language_switch';

type TrackingParam = PartialRecord<
  'user' | 'search' | 'location' | 'filter' | 'settings',
  Record<string, unknown>
>;

class Tracking {
  constructor() {
    // 'localStorage' isn't available while static site generation
    if (typeof window === 'undefined') {
      return;
    }

    const config = localStorage.getItem('mut-config');

    if (config) {
      const { uuid } = JSON.parse(config);

      this.track('app_open', {
        user: {
          uuid,
        },
      });

      return;
    }

    const uuid = uuidv4();

    this.track('app_install', {
      user: {
        os: getMobileOperatingSystem().toLocaleLowerCase(),
        system_lang: navigator.language.slice(0, 2),
        uuid,
      },
    });

    this.track('app_open', {
      user: {
        uuid,
      },
    });

    localStorage.setItem(
      'mut-config',
      JSON.stringify({
        uuid,
      }),
    );
  }

  track(event: TrackingEvent, param?: TrackingParam) {
    if (!window.dataLayer) {
      throw 'Called track(): Something went wrong. Seems Google Tag Manager was not initialised';
    }

    if (param) {
      // flush tracking data
      window.dataLayer.push({
        event,
        [Object.keys(param)[0]]: undefined,
      });
    }
    // tracking call
    window.dataLayer.push({
      event,
      ...param,
    });
  }
}

class NoTracking {
  track() {
    // mock track implementation, do not remove
  }
}

export const tracking =
  process.env.GATSBY_ENABLE_TRACKING === 'true' ? new Tracking() : new NoTracking();
