import {
  Accordion as MUIAccordion,
  styled,
  AccordionSummary as MUIAccordionSummary,
  AccordionDetails as MUIAccordionDetails,
  Divider as MUIDivider,
  AccordionActions as MUIAccordionActions,
  Typography as MUITypography,
} from '@mui/material';
import type { AccordionProps as MUIAccordionProps } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import React, { Fragment, SyntheticEvent, useEffect } from 'react';

export type AccordionProps = MUIAccordionProps & {
  /**
   * Custom props
   */
  summary: string;
  numberOfItems?: string;
  description?: string;
  footer?: JSX.Element | undefined;
  showExpand?: boolean;
};

const _Accordion = ({
  id,
  summary,
  numberOfItems,
  description,
  footer,
  showExpand,
  ...props
}: AccordionProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(
    showExpand ? `panel${summary}` : false,
  );

  useEffect(() => {
    setExpanded(showExpand ? `panel${summary}` : false);
  }, [showExpand]);

  const handleChange = (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <MUIAccordion
      {...props}
      expanded={expanded === `panel${summary}`}
      onChange={handleChange(`panel${summary}`)}
    >
      <MUIAccordionSummary
        sx={{
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
          },
        }}
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
      >
        {expanded == `panel${summary}` ? <Remove /> : <Add />}
        <MUITypography
          variant="subtitle1"
          sx={{
            padding: ({ spacing }) =>
              numberOfItems
                ? `${spacing(0.75)} ${spacing(10)} ${spacing(0.75)} ${spacing(1)}`
                : `${spacing(0)} ${spacing(2)} ${spacing(0)} ${spacing(1)}`,
          }}
        >
          {summary}
        </MUITypography>
        {numberOfItems ? (
          <MUITypography
            variant="body2"
            color="text.secondary"
            sx={{ position: 'absolute', right: ({ spacing }) => spacing(3) }}
          >
            {numberOfItems}
          </MUITypography>
        ) : null}
      </MUIAccordionSummary>

      <MUIAccordionDetails
        sx={{
          padding: ({ spacing }) =>
            numberOfItems
              ? `0 ${spacing(2)} 0 ${spacing(6.25)}`
              : `${spacing(1)} ${spacing(2)} ${spacing(2)} ${spacing(6.25)}`,
        }}
      >
        <MUITypography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
          {description}
        </MUITypography>
        {props.children}
      </MUIAccordionDetails>

      {footer ? (
        <Fragment>
          <MUIDivider />

          <MUIAccordionActions
            sx={{
              justifyContent: 'start',
              paddingLeft: '10px',
            }}
          >
            {footer}
          </MUIAccordionActions>
        </Fragment>
      ) : null}
    </MUIAccordion>
  );
};

export const Accordion = styled(_Accordion)<AccordionProps>(({ theme: { spacing } }) => ({
  padding: `${spacing(1)} ${spacing(1)}`,
  '&:before': {
    display: 'none',
  },
}));
